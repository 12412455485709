.background {
  min-width: 1024px;
  width: 100%;
  height: auto;
  filter: blur(3px);
  opacity: 100%;
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;
  margin: auto;
}
