@import url(https://fonts.googleapis.com/css?family=Sora);
body {
  margin: 0;
}

* {
  font-family: "Sora", sans-serif;
  margin: 0;
  padding: 0;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #344a06;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4faecf;
}

.background {
  min-width: 1024px;
  width: 100%;
  height: auto;
  -webkit-filter: blur(3px);
          filter: blur(3px);
  opacity: 100%;
}

.logo {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  display: block;
  margin: auto;
}

